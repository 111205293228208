import React, { Fragment } from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformExceptionList,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';
import spinnerPreviewData from '../../../data/previews/spinner.yml';
import pageHeroData from '../../../data/pages/components.yml';

const Spinner = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Spinner;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Spinner"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Spinner" />
      <V5Notice
        componentName="Spinner"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-spinner--docs"
      />

      <PageNavigation
        links={['Sizes', 'Mobile', 'Usage', 'Accessibility', 'Platform']}
      />

      <Section title="Sizes">
        <Paragraph>
          Choosing the right size depends on where the loading content lives.
        </Paragraph>
        <ComponentPreview
          name="SpinnerType"
          layout="split"
          previewData={spinnerPreviewData.sizes}>
          <Spinner />
        </ComponentPreview>
      </Section>

      <Section title="Mobile">
        <Paragraph>
          Spinners are closely aligned with <em>progress</em> on mobile. They’re
          generally used to represent activity, like pulling to refresh or
          synchronizing data. They might also be used to load a new experience
          or portion of the interface.
        </Paragraph>
        <PlatformExceptionList
          platforms={{
            apple: (
              <Fragment>
                Use the spinner when checking for updates, or loading new
                content into a list. Don’t use it when reporting determinate
                progress, and never replace the network activity indicator in
                the status bar. Instead follow Apple’s{' '}
                <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/controls/progress-indicators/">
                  recommendations
                </Link>
                .
              </Fragment>
            ),
            android: (
              <Fragment>
                Like Apple, when representing determinate progress, use progress
                indicators as outlined by{' '}
                <Link href="https://material.io/design/components/progress-indicators.html#usage">
                  Material's guidelines
                </Link>
                .
              </Fragment>
            ),
          }}
        />
      </Section>

      <Section title="Usage">
        <SectionSubhead>Not for Progress</SectionSubhead>
        <Paragraph>
          The spinner should never be used to indicate progress. There’s nothing
          to track, we’re simply completing a quick call to our servers.
        </Paragraph>
        <DontDo
          dontText="include any text regarding progress."
          doText="allow the spinner to speak for itself."
          imgFilename="spinner-textprogress"
        />

        <SectionSubhead>Position</SectionSubhead>
        <Paragraph>
          Always place spinners in the center of the content’s container. Don’t
          hide it in the corner like the loading isn’t important.
        </Paragraph>
        <Paragraph>
          If the loading content lives outside of the viewport, center
          horizontally instead.
        </Paragraph>
        <DontDo
          dontText="misplace a spinner where it may not be seen."
          doText="put it front and center to clearly indicate something’s happening."
          imgFilename="spinner-position"
        />

        <SectionSubhead>Limit per Page</SectionSubhead>
        <Paragraph>
          <strong>You get one spinner per page.</strong> Show too many at once
          and the user might get sick! If more than one piece of content is
          loading, stick to a large for the whole page.
        </Paragraph>
        <DontDo
          dontText="give each container its own spinner."
          doText="put one spinner in the center of the UI to indicate multiple pieces are loading."
          imgFilename="spinner-limit"
        />
      </Section>

      <AccessibilityAuditComponentResultsSection componentName="Spinner" />

      <Section title="Platform">
        <PlatformTable />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
